<template>
  <div class="fo">
    <div class="foot-box">
      <div class="foot">
        <div class="foot_left">
          <p class="foot_title">科研服务单位：</p>
          <p class="foot_indent">中政普华（北京）文化传播机构</p>
          <p class="foot_title">微信平台 / 国学中心：</p>
          <p class="foot_indent">(微信号：guoxuezhongxin0928)</p>
          <p class="foot_title">联系电话:</p>
          <p class="foot_indent">185 1507 0887 (微信同号) 滕老师</p>
          <p class="foot_indent">185 1507 0806 (微信同号) 郝老师</p>
          <p class="foot_title"><a href="../application/legal">法律声明</a></p>
          <p class="foot_indent">
            <a href="https://beian.miit.gov.cn/" target="_blank"
              >京ICP备19020077号-3</a
            >
          </p>
        </div>
        <div class="foot_center">
          <p class="foot_title">指导单位：</p>
          <p class="foot_indent">教育部教育装备研究与发展中心（原）</p>
          <p class="foot_title">主办单位：</p>
          <p class="foot_indent">中小学传统文化数字资源研究传播中心（原）</p>
          <p class="foot_indent">人民教育出版社</p>
          <p class="foot_indent">中国留学人才发展基金会汉学研究院</p>
          <p class="foot_title">合作单位：</p>
          <p class="foot_indent">中国国学文化艺术中心</p>
          <p class="foot_indent">
            教育部规划课题《传统文化与中小学人格培养研究》总课题组
          </p>
          <p class="foot_indent">
            东方文化与城市发展研究所《中华优秀传统文化创新性传承专项研究》总课题组
          </p>
        </div>
        <div class="foot_right">
          <div class="foot_right_1">
            <p><a href="/application/ziyuanzhongxin">资源中心简介</a></p>
            <p><a href="/application/catalog">科研产品目录</a></p>
            <p><a href="/application">课题申报专区</a></p>
            <p><a href="/video/train">师资培训专区</a></p>
            <p>
              <a href="/xuekejiaoyan/workstation" class="workstation"
                >地区工作站</a
              >
            </p>
            <img class="foot_img" src="../assets/images/erweima.jpg" alt="" />
            <p class="foot_img_text">国学中心 公众平台</p>
          </div>
          <div class="foot_right_1">
            <p><a href="/application/development">课题研究历程</a></p>
            <p><a href="/application/fazhanlichengzixun">发展历程资讯</a></p>
            <p><a href="/application/jidiminglu">课题基地名录</a></p>
            <p><a href="/train/talentPool">专业人才检索</a></p>
            <p><a href="/newsCate">焦点新闻</a></p>
            <img
              class="foot_img"
              src="../assets/images/hanxuexiaopu.jpg"
              alt=""
            />
            <p class="foot_img_text2">汉学小铺</p>
          </div>
        </div>
      </div>
    </div>
    <div class="cloud-fixbar">
      <ul>
        <li>
          <router-link
            :to="{
              path: '/news',
              query: { id: 21 },
            }"
          >
            <span>征召中小学传统文化创新实验区</span></router-link
          >
        </li>

        <li>
          <router-link
            :to="{
              path: '/news',
              query: { id: 22 },
            }"
            ><span>征召中小学传统文化综合实践创新实验示范校</span></router-link
          >
        </li>

        <!-- <li>
          <router-link
            :to="{
              path: '/news',
              query: { id: 23 },
            }"
            ><span
              >征召中小学传统文化综合实践教学体系全国代理</span
            ></router-link
          >
        </li> -->
      </ul>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.foot-box {
  width: 100%;
  height: 340px;
  min-width: 1484px;
  background: url(../assets/images/but.jpg);
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
}
.foot_title > a {
  color: #fff;
}
.foot_title > a:hover {
  color: rgb(128, 20, 20);
}
.foot_left a:hover,
.foot_right_1 a:hover {
  color: rgb(128, 20, 20);
}
.foot_right > .foot_right_1 > .foot_img_text2 {
  position: absolute;
  top: 288px;
  left: 40px;
  font-size: 18px;
}

.cloud-fixbar {
  position: fixed;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  /* box-shadow: 2px 2px 2px #f2f2f2; */
}

.cloud-fixbar > ul {
  position: relative;
  height: auto;
  /* background-color: #607a5d; */
  background: url(../assets/images/fixd_right_bg.png);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  font-size: 12px;
  width: 100px;
}

.cloud-fixbar > ul li {
  position: relative;
  padding: 10px;
  transition: all 0.5s ease;
  border-bottom: 1px solid grey;
}

.cloud-fixbar > ul li:last-child {
  border-bottom: none;
}

.cloud-fixbar > ul li span {
  font-size: 14px;
  line-height: 20px;
}

.cloud-fixbar * {
  color: #a85920;
  font-weight: bold;
  text-align: center;
}

.workstation {
  letter-spacing: 5px;
}
</style>