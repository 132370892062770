import request from "@utils/request";

/**
 * 首页
 * @returns {*}
 */
export function getNavDatas() {
  return request.get("navs", "", { login: false });
}

/* 根据导航分类id获取导航列表  */
export function getNavsByCid(id) {
  return request.get("navsByCid", id, { login: false });
}

/**
 * 获取轮播图数据列表
 */
export function getcarouselsByCid(id) {
  return request.get("carouselsByCid", id, { login: false });
}

/*创新课程体系  */
export function getPracticeCourses() {
  return request.get("practiceCoursesByModular", "", { login: false });
}

/*创新课程体系年级分类列表  */
export function getPracticeGradeCategorys() {
  return request.get("PracticeCategorys", "", { login: false });
}


/*根据创新课程体系年级分类id获取中医启蒙列表  */
export function getPracticeMedicinesByGradeCategoryId(id) {
  return request.get("practiceMedicinesByGradeCategoryId", id, { login: false });
}

/*根据创新课程体系年级分类id获取主要阅读部分的文字列表  */
export function getPracticeMedicinesMainReadByGradeCategoryId(id) {
  return request.get("practiceMedicinesMainReadByGradeCategoryId", id, { login: false });
}

/*根据创新课程体系章节分类详情id获取视频播放playauth  */
export function getCoursePlayAuthByDivisionContentId(id) {
  return request.get("coursePlayAuthByDivisionContentId", id, { login: false });
}

/*根据创新课程体系中医启蒙课程id获取里面的division_category列表  */
export function getPracticeMedicinesByMedicineId(id) {
  return request.get("practiceMedicinesByMedicineId", id, { login: false });
}

/*根据创新课程体系中医启蒙课程division_id获取里面的division_category列表  */
export function getPracticeMedicineDivisionChildList(queryWhere) {
  return request.get("practiceMedicineDivisionChildList", queryWhere, { login: false });
}


/* 根据模块id获取创新课程体系 */
export function practiceCoursesByModularId(id) {
  return request.get("practiceCoursesByModularId", id, { login: false });
}

/* 创新课程体系三级页 */
export function getpracticeCourse(queryWhere) {
  return request.get("practiceCourse", queryWhere, { login: false });
}
// 创新课程体系三级页详情页
export function getPracticeTabs(queryWhere) {
  return request.get("practiceTabsByPracticeCourseId", queryWhere, {
    login: false,
  });
}

// 创新课程体系主题导读列表
export function getpracticeTopicGuides(queryWhere) {
  return request.get("practiceTopicGuideByPracticeContentId", queryWhere, {
    login: false,
  });
}
/* 理论研究中心 */
export function gettheoryCourses() {
  return request.get("theoryCourses", "", { login: false });
}
/* 理论研究中心三级页 */
export function gettheoryCourse(queryWhere) {
  return request.get("theoryCourse", queryWhere, { login: false });
}

/* 理论研究中心三级页内容详情 */
export function getTheoryCourseDivisionContent(queryWhere) {
  return request.get("theoryCourseDivisionContent", queryWhere, {
    login: false,
  });
}

/* 传统课程体系 */
export function gettraditionCourses() {
  return request.get("traditionCourses", "", { login: false });
}

export function gettradition(queryWhere) {
  return request.get("traditionCourse", queryWhere, { login: false });
}

export function getTraditionCourseDivisionContent(queryWhere) {
  return request.get("traditionCourseDivisionContent", queryWhere, {
    login: false,
  });
}

/* 教师备课资源列表*/
export function getpraparationCourses() {
  return request.get("praparationCourses", "", { login: false });
}

export function getpraparationCoursess(queryWhere) {
  return request.get("praparationCourse", queryWhere, { login: false });
}
/* 教师备课资源详情 */
export function getpraparationCourse(queryWhere) {
  return request.get("praparationCourseByIdAndEditionId", queryWhere, {
    login: false,
  });
}

/* 教师备课资源详情模块内容 */
export function getpraparationDivisionContentByContenId(queryWhere) {
  return request.get("praparationDivisionContentByContenId", queryWhere, {
    login: false,
  });
}

/* 师资培训和多媒体中心 */
export function getvideosByCateId(queryWhere) {
  return request.get("videosByCateId", queryWhere, { login: false });
}

/* 多媒体中心详情页 */
export function getvideo(queryWhere) {
  return request.get("video", queryWhere, { login: false });
}

/* 课题实验专区 */
export function getencryptionPracticeCourse(queryWhere) {
  return request.get("encryptionPracticeCourse", queryWhere, { login: false });
}

export function getEncryptionVideo(queryWhere) {
  return request.get("encryptionVideo", queryWhere, { login: false });
}
export function getUserTrainCourse(queryWhere) {
  return request.get("userTrainCourse", queryWhere, { login: true });
}
/* 课题实验专区 详情 */
export function getvideosBycate(queryWhere) {
  return request.get("videosBycate", queryWhere, { login: false });
}
/* 发展历程列表 详情 */
export function getvideoReviews(queryWhere) {
  return request.get("videoReviews", queryWhere, { login: false });
}
/* 发展历程 详情 */
export function getvideoReview(queryWhere) {
  return request.get("videoReview", queryWhere, { login: false });
}
/* 辅助教学目录 详情 */
export function getbookCategorys(queryWhere) {
  return request.get("bookCategorys", queryWhere, { login: false });
}
/* 法律声明 详情 */
export function getarticleh(queryWhere) {
  return request.get("articleh", queryWhere, { login: false });
}
/* 根据文章分组id获取分类列表 */
export function getarticlehsByCid(queryWhere) {
  return request.get("articlehsByCid", queryWhere, { login: false });
}
/* 辅助阅读 */
export function getDivisionContentByContentId(id) {
  return request.get("DivisionContentByContentId", id, { login: false });
}
/* 课题研究介绍 详情 */
export function getkeyProjects(queryWhere) {
  return request.get("keyProjects", queryWhere, { login: false });
}
/* 课题研究介绍列表 详情 */
export function getkeyProjectChildrensById(queryWhere) {
  return request.get("keyProjectChildrensById", queryWhere, { login: false });
}
export function getkeyProject(queryWhere) {
  return request.get("keyProject", queryWhere, { login: false });
}
/* 根据图书分类id获取图书列表 */
export function getbooksByCid(queryWhere) {
  return request.get("booksByCid", queryWhere, { login: false });
}

/* 根据图书分类id获取图书分类详情 */
export function getBookCategory(queryWhere) {
  return request.get("bookCategory", queryWhere, { login: false });
}


/* 根据图书id获取图书详情 */
export function getBook(queryWhere) {
  return request.get("book", queryWhere, { login: false });
}

/* 个人中心课程详情 */
export function getuserTrainCourse(queryWhere) {
  return request.get("userTrainCourse", queryWhere, { login: true });
}

/* 人才库数据列表 */
export function gettrainPersons(page) {
  return request.get("trainPersons", page, { login: false });
}

/* 模拟试题 */
export function getExams(queryWhere) {
  return request.get("Exams", queryWhere, { login: true });
}


/* 模拟考试提交 */
export function saveExam(data) {
  return request.post("saveExam", data, { login: true });
}


/* 人才库搜索 */
export function getSearchTrainPersons(queryWhere) {
  return request.get("searchTrainPersons", queryWhere, { login: false });
}

/* 获取视频培训专区 */
export function getChildrenCates(queryWhere) {
  return request.get("childrenCates", queryWhere, { login: false });
}

/* 获取视频培训专区 */
export function getVideoCate(queryWhere) {
  return request.get("videoCate", queryWhere, { login: false });
}

/*获取资源展示详情  */
export function getResourceDisplay(queryWhere) {
  return request.get("resourceDisplay", queryWhere, { login: false });
}