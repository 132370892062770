<template>
  <div class="ProjectIntroductionDetails">
    <heade></heade>
    <div class="cen_wrap">
      <div class="cen_box">
        <div class="cen_left">
          <div class="cen_left_title">
            {{ keyProjectChildrens.name }}
          </div>
          <div class="cen_left_con">
            <div v-for="(item, index) in keyChildrens" :key="index">
              <div
                v-if="item.children.length == 0"
                @click="getkeyProject(item.id)"
                :id="'trigger' + index"
                v-trigger
                :class="queryWhere.id == item.id ? 'addclass' : ''"
              >
                {{ item.name }}
              </div>

              <div
                v-else
                @click="getkeyProjectChildrensById(item.id)"
                :id="item.id"
                :class="queryWhere.id == item.id ? 'addclass' : ''"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="cen_right">
          <div v-if="content == ''">
            <div class="k">
              <div class="kdiv">
                <div v-html="articleh.content"></div>
              </div>
              <div class="kdivaa">
                <div class="kdiva">
                  <div v-for="(items, indexs) in children" :key="indexs">
                    <router-link
                      class="keys"
                      :to="{
                        path: pathUrl,
                        query: { id: items.id },
                      }"
                      >{{ items.name }}</router-link
                    >
                  </div>
                </div>
                <div style="font-size: 16px; text-align: center">
                  <router-link
                    :to="{
                      path: articleha.link_url,
                    }"
                    >{{ articleha.name }}</router-link
                  >
                </div>
                <div
                  style="font-size: 16px; text-align: center; margin-top: 10px"
                >
                  {{ articlehas.name }}
                </div>
              </div>
            </div>
          </div>
          <div v-if="content != ''" class="key_project_play">
            <div
              class="prism-player-box"
              v-if="options.source != '' && queryWhere.id == 25"
            >
              <vue-aliplayer-v2 ref="VueAliplayerV2" :options="options" />
            </div>
            <div v-html="content"></div>
          </div>
        </div>
      </div>
      <div class="link_box">
        <div class="link">
          <router-link class="add" to="/application/ProjectIntroduction"
            >课题研究介绍</router-link
          >
        </div>
        <div class="link" v-for="(item, index) in bookCategorys" :key="index">
          <router-link
            :to="{
              path: path2,
              query: { id: item.id },
            }"
            >{{ item.name }}</router-link
          >
        </div>
        <div class="link">
          <router-link to="AuxiliaryReading1"
            >人教版全国中小学传统文化教学一体机</router-link
          >
        </div>
        <div class="link">
          <router-link to="AuxiliaryReading2"
            >中小学传统文化数字资源研究传播中心配套校园终端服务器
          </router-link>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import "@css/ico.css";
import "@css/foot.css";
import heade from "@components/heade.vue";
import foot from "@components/foot.vue";
import VueAliplayerV2 from "vue-aliplayer-v2";
import {
  getkeyProjectChildrensById,
  getkeyProject,
  getbookCategorys,
  getarticleh,
} from "@api/public";
export default {
  components: {
    heade,
    foot,
    VueAliplayerV2,
  },
  props: {},
  data: function () {
    return {
      keyProjectChildrens: {},
      keyChildrens: [],
      backgroud: "",
      queryWhere: {},
      bookCategorys: [],
      children: [],
      path2: "",
      content: "",
      articleh: {},
      articleha: {},
      articlehas: {},
      options: {
        autoplay: false,
        height: "100%",
        playsinline: true,
        source: "",
        playsinline: true,
        cover: "",
      },
    };
  },
  mounted: function () {
    let that = this;
    window.onresize = function () {
      return (function () {
        that.$router.go(0);
      })();
    };
    getkeyProjectChildrensById(that.queryWhere).then(function (res) {
      that.$set(that, "keyProjectChildrens", res.data);
      that.$set(that, "keyChildrens", res.data.children);
    });
    getbookCategorys().then(function (res) {
      that.$set(that, "bookCategorys", res.data);
    });
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    },
  },

  created: function () {
    this.queryWhere = this.$route.query;
    document.title = this.$route.meta.title;
    this.pathUrl = this.$route.meta.path;
    this.path2 = this.$route.meta.pathlink;
    this.backgroud = this.$route.meta.background;
  },
  methods: {
    getkeyProjectChildrensById: function (id) {
      let that = this;
      that.queryWhere = {};
      that.queryWhere.id = id;
      that.content = "";
      getkeyProjectChildrensById(that.queryWhere).then(function (res) {
        that.$set(that, "children", res.data.children);
      });
      if (id == 5) {
        getarticleh({ id: 10 }).then(function (res) {
          that.$set(that, "articleh", res.data);
        });
      }
      if (id == 27) {
        getarticleh({ id: 13 }).then(function (res) {
          that.$set(that, "articleh", res.data);
        });
        getarticleh({ id: 14 }).then(function (res) {
          that.$set(that, "articleha", res.data);
        });
        getarticleh({ id: 15 }).then(function (res) {
          that.$set(that, "articlehas", res.data);
        });
      }
    },

    getkeyProject: function (id) {
      let that = this;
      that.queryWhere = {};
      that.queryWhere.id = id;
      getkeyProject(that.queryWhere).then(function (res) {
        that.$set(that, "content", res.data.content);
        that.$set(that.options, "source", res.data.video_url);
      });
    },
  },
  directives: {
    trigger: {
      inserted(el, binging) {
        el.id == "trigger0" ? el.click() : null;
      },
    },
  },
};
</script>

<style>
.ProjectIntroductionDetails .cen_wrap {
  width: 100%;
  height: 1762px;
  background: url(../../assets/images/ketijieshao2.jpg);
  background-size: cover;
  background-position: center 0;
}
.ProjectIntroductionDetails .cen_box {
  width: 1484px;
  height: 990px;
  padding-top: 326px;
  margin: 0 auto;
  display: flex;
}
.cen_left {
  width: 365px;
  height: 100%;
}
.cen_left_title {
  font-family: kaiti;
  height: 215px;
  font-size: 24px;
  color: #e2cea0;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  position: relative;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
}
.cen_left_title > span {
  font-family: kaiti;
  height: 280px;
  font-size: 30px;
  color: #e2cea0;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
}
.cen_left_title::after {
  content: " ";
  display: block;
  width: 53px;
  height: 132px;
  background: url(../../assets/images/biaozuo.jpg) no-repeat;
  background-size: 53px 132px;
  right: 5px;
  top: 0;
  bottom: 0;
  margin: auto 0;
}
.cen_left_title::before {
  content: " ";
  display: block;
  height: 132px;
  width: 53px;
  background: url(../../assets/images/biaoyou.jpg) no-repeat;
  background-size: 53px 132px;
  position: relative;
  top: 0;
  bottom: 0;
  margin: auto 0;
  left: 5px;
}
.cen_left_con {
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
  padding-top: 100px;
}
.cen_left_con > div {
  height: 80px;
  line-height: 80px;
}
.cen_left_con > div > div {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}
.cen_right {
  width: 990px;
  height: 770px;
  margin-top: 90px;
  overflow-y: auto;
  scrollbar-width: none;
  padding: 50px 50px;
  box-sizing: border-box;
}

.cen_left_con > div > .addclass {
  color: #e2cea0;
}
.addclass::after {
  content: "·";
  display: block;
  font-size: 18px;
  font-weight: 900;
  padding-left: 10px;
}
.addclass::before {
  content: "·";
  display: block;
  font-size: 18px;
  font-weight: 900;
  padding-right: 10px;
}
.ProjectIntroductionDetails .link_box {
  width: 800px;
  height: 250px;
  position: relative;

  left: 460px;
  display: flex;
  align-items: center;
}
.ProjectIntroductionDetails .link {
  height: 220px;
  writing-mode: vertical-rl;
  padding-right: 60px;
}

.ProjectIntroductionDetails .link > a {
  color: #006649;
  font-size: 18px;
  font-family: kaiti;
  font-weight: 600;
  line-height: 26px;
}
.link > a:hover {
  color: #900c0c;
}
.ProjectIntroductionDetails .link > .add {
  color: #900c0c;
}
.keys {
  display: inline-block;
  font-size: 16px;
  font-family: 幼圆;
  margin-left: 40%;
  margin-top: 40px;
}
.cen_right::-webkit-scrollbar {
  display: none;
}
.cen_right > div > p > img {
  width: 100%;
}
.k {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.kdiv {
  width: 480px;
  height: 770px;
  overflow-y: scroll;
  scrollbar-width: none;
}
.kdivaa {
  width: 480px;
  height: 770px;
}
.kdiva {
  width: 480px;
  height: 500px;
  overflow-y: scroll;
  scrollbar-width: none;
}
.kdiv::-webkit-scrollbar {
  display: none;
}
.kdiva::-webkit-scrollbar {
  display: none;
}
.k > div > div > p > img {
  width: 100%;
}

.key_project_play .prism-player video {
  position: relative !important;
  top: 260px !important;
  height: 520px !important;
}
</style>